/* eslint-disable */

<template>
  <div class="vx-row Lgx202142297964412928">
    <div class="vx-col w-full">
      <vx-card class="mb-base">
        <div class="fourth-sim pt-0">
          <div class="vx-row mb-4">
            <div class="vx-col w-4/12 flex items-center">
              <h2 class="font-bold">Attack Description</h2>
            </div>
            <div class="vx-col w-2/12 flex items-center">
              <h2 class="font-bold">Target</h2>
            </div>
            <div class="vx-col w-3/12 flex items-center">
              <h2 class="font-bold">Attack Identified</h2>
            </div>
            <div class="vx-col w-3/12 flex items-center">
              <h2 class="font-bold">Best Action Possible</h2>
            </div>
          </div>
          <div class="vx-row items-center border-white border-2 border-solid rounded p-2 my-4" v-for="item in table_items" :key="item.id">
            <div class="vx-col w-4/12 flex items-center p-3">
              <h4 class="pr-24">{{ item.description }}</h4>
            </div>
            <div class="vx-col w-2/12 p-3">
              <h4>{{ item.target }}</h4>
            </div>
            <div class="vx-col w-3/12 p-3">
              <v-select
                placeholder="Select an attack from the dropdown"
                class="w-full"
                :options="attack_options"
                v-model="item.attack_selected"
              />
              <small v-if="item.attack_is_correct !== null && item.attack_is_correct === false" class="ml-1 text-danger font-semibold"
                >This answer is invalid</small
              >
              <small v-if="item.attack_is_correct !== null && item.attack_is_correct === true" class="ml-1 text-success font-semibold"
                >This answer is correct</small
              >
            </div>
            <div class="vx-col w-3/12 p-3">
              <v-select
                placeholder="Select an action from the dropdown"
                class="w-full"
                :options="action_options"
                v-model="item.action_selected"
              />
              <small v-if="item.action_is_correct !== null && item.action_is_correct === false" class="ml-1 text-danger font-semibold"
                >This answer is invalid</small
              >
              <small v-if="item.action_is_correct !== null && item.action_is_correct === true" class="ml-1 text-success font-semibold"
                >This answer is correct</small
              >
            </div>
          </div>
        </div>
      </vx-card>
    </div>
  </div>
</template>

<script>
import vSelect from 'vue-select';
import Prism from 'vue-prism-component';
import shuffle from 'shuffle-array';

export default {
  data() {
    return {
      dataLocal: JSON.parse(JSON.stringify(this.data)),

      update_index: 0,

      table_items: [
        {
          id: 1,
          description: 'An attacker sends multiple SYN packets from multiple sources',
          target: 'Web Server',
          attack_selected: null,
          action_selected: null,
          attack_correct: 1,
          action_correct: 7,
          attack_is_correct: null,
          action_is_correct: null,
        },
        {
          id: 2,
          description: 'The attack establishes a connection, which allows remote commands to be executed',
          target: 'User',
          attack_selected: null,
          action_selected: null,
          attack_correct: 2,
          action_correct: 8,
          attack_is_correct: null,
          action_is_correct: null,
        },
        {
          id: 3,
          description: 'The attack is self-propagating and compromises a SQL database using well-known credentials.',
          target: 'Database Server',
          attack_selected: null,
          action_selected: null,
          attack_correct: 3,
          action_correct: 9,
          attack_is_correct: null,
          action_is_correct: null,
        },
        {
          id: 5,
          description: 'The attacker uses hardware to remotely monitor a users input activity to harvest credentials',
          target: 'Executive',
          attack_selected: null,
          action_selected: null,
          attack_correct: 4,
          action_correct: 10,
          attack_is_correct: null,
          action_is_correct: null,
        },
        {
          id: 6,
          description: 'The attacker embeds hidden access in an internally developed application that bypasses accounts login',
          target: 'Application',
          attack_selected: null,
          action_selected: null,
          attack_correct: 5,
          action_correct: 11,
          attack_is_correct: null,
          action_is_correct: null,
        },
      ],

      attack_options: [
        { id: 1, label: 'Botnet' },
        { id: 2, label: 'RAT' },
        { id: 3, label: 'Worm' },
        { id: 4, label: 'Keylogger' },
        { id: 5, label: 'Backdoor' },
        { id: 6, label: 'Logic Bomb' },
        { id: 7, label: 'Virus' },
        { id: 8, label: 'Spyware' },
        { id: 9, label: 'Adware' },
        { id: 10, label: 'Ransomware' },
        { id: 11, label: 'Phishing' },
      ],

      action_options: [
        { id: 1, label: 'Patch vulnerable systems' },
        { id: 2, label: 'Implement a proxy with sandboxing' },
        { id: 3, label: 'Disable vulnerable services' },
        { id: 4, label: 'Update the cryptographic algorithms' },
        { id: 5, label: 'Implement application fuzzing' },
        { id: 6, label: 'Implement a host-based IDS' },
        { id: 7, label: 'Enable DDos protection' },
        { id: 8, label: 'Disable remote access services' },
        { id: 9, label: 'Change the default system password' },
        { id: 10, label: 'Implement 2FA using push notification' },
        { id: 11, label: 'Conduct a code review' },
      ],
    };
  },
  methods: {
    markSimulation() {
      let totalScore = 0;

      this.table_items.forEach((x, i) => {
        if (x.attack_selected && x.attack_selected.id === x.attack_correct) {
          this.table_items[i].attack_is_correct = true;
          totalScore++;
        } else {
          this.table_items[i].attack_is_correct = false;
        }
        if (x.action_selected && x.action_selected.id === x.action_correct) {
          totalScore++;
          this.table_items[i].action_is_correct = true;
        } else {
          this.table_items[i].action_is_correct = false;
        }
      });

      if (totalScore === this.dataLocal.simulation.totalScore) {
        return this.$emit('simulation_full_correct', totalScore);
      }

      this.$emit('simulation_getting_there', totalScore);
    },
  },
  components: {
    Prism,
    vSelect,
    shuffle,
  },
  props: {
    data: {
      type: Object,
      required: true,
    },
  },
};
</script>
<style>
.Lgx202142297964412928 {
  font-size: 19px;
}
</style>
